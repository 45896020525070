.about_body {
    min-height: 100vh;
}
.about_section_one_div {
    position: absolute;
    top: 32vh;
    color: white;
    padding-left: 42%;
    z-index: 2;
}
.about_section_one_div > h1{
    font-size: 55px;
    font-weight: 900;
    color: white;
    letter-spacing: 5px;
}
#bgvid2 {
    object-fit: cover;
    width: 100%;
    height: 70vh;
    z-index: 1;
    margin: 0;
}
.about_section_two {
    padding: 5% 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.about_section_two_div1{
    width: 50%;
}
.about_section_two_div1 > p{
    line-height: 35px;
}
.about_section_two_div2{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about_section_two_div2 > p{
    line-height: 30px;
    letter-spacing: 3px;
    font-weight: 200;
    font-size: 25px;
    color: red;
}
.about_section_three {
    background: url('./partner2.jpg');
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about_section_three > h1{
    font-size: 70px;
    color: white;
}
.about_section_three > h3{
    font-size: 20px;
    color: lightgray;
    letter-spacing: 2px;
    text-align: center;
    padding: 0 17%;
}
.about_section_four {
    padding: 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about_section_four > h3{
    font-weight: 400;
    font-size: 15px;
}
.about_section_four > h1{
    line-height: 30px;
    letter-spacing: 3px;
    font-weight: 700;
    font-size: 35px;
    color: black;
    margin: 0;
}

.about_section_four > div{
    display: flex;
    padding: 2.5% 15%;
    justify-content: space-between;
}
.about_vision {
    width: 45%;
    background: #f5f5f5;
    padding: 1% 2.5%;

}
.about_mission {
    width: 45%;
    background: #f5f5f5;
    padding: 1% 2.5%;
}
.about_section_four > div  h3{
    font-weight: 600;
    color: #003333;
    text-align: center;
}
.about_section_four > div p{
    line-height: 30px;
    font-weight: 200;
}
.about_img {
    width: 100%;
    height: 300px;
}
@media all and (max-width: 1200px) {
    .about_section_one {
        padding: 5%;
    }
    .about_section_two {
        padding: 5%;
        justify-content: center;
        flex-direction: column;
    }
    .about_section_two_div1{
        width: 100%;
    }
    .about_section_two_div1 > p{
        line-height: 35px;
        text-align: justify;
    }
    .about_section_two_div2{
        width: 100%;
    }
    .about_section_four > div{
        flex-direction: column;
        padding: 5%;
        justify-content: center;
    }
    .about_vision {
        width: 100%;
        padding: 0 5%;
        margin-bottom: 30px;
        text-align: justify;
    }
    .about_mission {
        width: 100%;
        padding: 0 5%;
        text-align: justify;
    }
    
}
@media all and (max-width: 700px) {
    .about_section_one {
        padding: 5%;
    }
    .about_section_two {
        padding: 5%;
        justify-content: center;
        flex-direction: column;
    }
    .about_section_two_div1{
        width: 100%;
    }
    .about_section_two_div1 > p{
        line-height: 35px;
        text-align: justify;
    }
    .about_section_two_div2{
        width: 100%;
    }
    .about_section_four > div{
        flex-direction: column;
        padding: 5%;
        justify-content: center;
    }
    .about_vision {
        width: 100%;
        padding: 0 5%;
        margin-bottom: 30px;
        text-align: left;
    }
    .about_mission {
        width: 100%;
        padding: 0 5%;
        text-align: left;
    }
    .about_img {
        width: 100%;
        height: 400px;
    }
    .about_section_three > h1{
        font-size: 60px;
        color: white;
        padding: 0 5%;

    }
    .about_section_three > h3{
        font-size: 20px;
        color: lightgray;
        letter-spacing: 2px;
        text-align: start;
        padding: 0 5%;
        font-weight: 500;
    }
    .about_section_one_div {
        top: 32vh;
        padding-left: 18%;
    }
    
}