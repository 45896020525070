.sustainability {
    min-height: 100vh;
} 
.sustainability_body_header {
    min-height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('./partner2.jpg');
    background-position: center;
}
.sustainability_body_header > h1{
    font-size: 55px;
    font-weight: 900;
    color: white;
    letter-spacing: 5px;
}
.sustainability_body_content {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 25px;
    padding: 0 20%;
    text-align: justify;
    font-weight: 500;
}
@media all and (max-width: 700px) {
    .sustainability_body_content {
        padding: 5%;
    }  
}
