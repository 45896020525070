.header {
    min-height: 8vh;
    box-shadow: 0.5px 0.5px 1.5px -1px #28282b;
    background: white;
    position: fixed;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 22%;
    justify-content: center;
    opacity: 0.75;

}
.header.transparent {
    min-height: 8vh;
    box-shadow: 0.5px 0.5px 1.5px -1px #28282b;
    background: transparent;
    position: fixed;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 22%;
    justify-content: center;
    opacity: 1;
    background: white;
}
.header_nav {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    color: #000;
    font-size: 13px;
    font-weight: 500;
}
.header_nav_list{
    margin: 0 20px;
    cursor: pointer;
    text-decoration: none;
    color: black;
}
.header_nav_list:hover{
    color: #ffcc00;
}
.header > button {
    margin-left: auto;
    padding: 10px 20px;
    font-size: 12.5px;
    color: white;
    background-color: #db7a00;
    border: 0;
    border-radius: 2.5px;
    cursor: pointer;
    font-weight: 200;
    transition: 1.5s linear;    
}
.home_menu {
    display: none;
    color: #1d293f;
    font-size: 30px;
    margin-left: auto;
    z-index: 25;
}
.home_sidebar{
    position: absolute;
    z-index: 100;
    background-color: #003333;
    color: white;
    top: 0;
    left: -100%;
    width: 95%;
    margin: 0;
    min-height: 100vh;
    transition: all 0.75s linear;
    
}
.home_sidebar_true{
    position: absolute;
    z-index: 100;
    background-color: #003333;
    color: white;
    top: 0;
    left: 0;
    width: 95%;
    margin: 0;
    min-height: 100vh;
    transition: all 0.75s linear;
    
}
.home_sidebar_close {
    font-size: 30px;
    color: white;
    margin: 2.5%;
    margin-left: auto;
}
.home_sidebar_navbar{
    list-style-type: none;
    display: flex;
    min-height: 80vh;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.home_sidebar_navbar > li{
    margin: 7% 0;
    width: 100%;
    padding: 0 10px;
}
.home_sidebar_link {
    text-decoration: none;
    font-size: 18px;
    color: white;
}
.evajay_header {
    object-fit: contain;
    height: 60px;
}
.evajay_header2{
    object-fit: contain;
    height: 60px;
}

@media (orientation: landscape) {
    .home_sidebar{
        position: absolute;
        z-index: 100;
        background-color: #28282b;
        color: white;
        top: 0;
        left: -100%;
        width: 85%;
        margin: 0;
        min-height: 100vh;
        transition: all 0.75s linear;
    }
    .home_sidebar_true{
        position: absolute;
        z-index: 100;
        background-color: #28282b;
        color: white;
        top: 0;
        left: 0;
        width: 85%;
        margin: 0;
        min-height: 100vh;
        transition: all 0.75s linear;
    
    }
    .home_sidebar_close {
        font-size: 30px;
        color: white;
        margin: 2.5%;
    }
    .home_sidebar_navbar{
        list-style-type: none;
        display: flex;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    .home_sidebar_navbar > li{
        margin: 2% 0;
        width: 100%;
        padding: 0 10px;
    }
    .home_sidebar_link {
        text-decoration: none;
        font-size: 18px;
        color: white;
    }
}
@media all and (max-width: 1600px) {
    .header_nav{
       display: none;
       width: 100%;
    }
    .header > button {
        display: none;   
    }
    .header_prime {
        font-size: 22px;
    }
    .header {
        display: flex;
        justify-content: start;
        padding: 0 2%;
        min-height: 8vh;
        opacity: 1;
    }
    .header.transparent {
        padding: 0 2%;
        opacity: 1;
        
    }
    .home_menu {
        display: block;
    }
    .evajay_header {
        margin: 10px;
    }
    .evajay_header2 {
        margin: 20px 50px;
    }
    
}
